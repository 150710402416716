.filter-container {
	display: grid;
	column-gap: 4rem;
	margin-bottom: 3rem;
	
	.check-anonym,
	.search-container {
		display: flex;
		align-items: center;
		input[type="checkbox"] {
			width: fit-content;
			margin-right: 1rem;
		}
		input{
			width: 100%;
			padding: 0.375rem 0.75rem;
			border-radius: 0.25rem;
			margin-left: -3px;
		}
		.input-group-text{
			padding: 0.575rem 0.75rem;
		}
	}
	.filter-category, .date-container{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		
		.date-picker{
			button{
				color :white;
				margin-left: -3rem;
				z-index: 2;
			}
		}
		.date-picker-inputGroup, .filter-inputGroup{
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			margin-right: 1rem;
			label{
				margin-right: 1rem;
			}
		}
		select,
		input {
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			border-radius: 0.25rem;
		}
		label {
			margin: 0;
		}
		.input-group-text {
			height: 34px;
		}
	}
	@media (min-width: 1600px) {
		grid-template-columns: 1fr 1fr 1fr;
		.search-container {
			width: 80%;
			margin-left: 4rem;
		}
		.filter-category, .date-container{
			width: max-content;
		}
	}

	@media (max-width: 1440px) {
		grid-template-rows: 1fr 1fr 1fr;
		gap: 1rem;
		.search-container, .filter-category, .date-container {
			width: 100%;
		}
	}   
}

.btn-container{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 0.75rem;
	.btn.btn-success{
		margin-right: 0;
	}
}