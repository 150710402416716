.dropzone {
	position: relative;
	width: 50%;
	margin: 4rem auto;
	height: 300px;
	user-select: none;
	cursor: pointer;

	.file-drop-target {
		position: absolute;
		width : 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-content: center;

		text-align: center;
		border: dashed 3px;
		border-radius: 2rem;
	}

	.file-drop-target.file-drop-dragging-over-target {
		background: rgba(0,0,0,0.1);
	}
}

.manual-file-input {
	display: none;
}

.preview-table {
	overflow-x: auto;
	
	table {
		width: initial;
		min-width: 100%;
	}
}

.excel-table {
	overflow-x: auto;
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	max-width: 100%;
	box-sizing: border-box;

	table {
		width: 100%;
		border-collapse: collapse;
		border: solid 1px #ccc;

		tr {
			th {
				text-align: center;
				font-weight: bold;
				background: #ccc;
				border-color: #aaa !important;
			}

			th, td {
				border: solid 1px #ccc;
				padding: 0.2rem 0.8rem;
				min-width: 8rem;
				white-space: nowrap;
			}

			&.headline {
				td {
					text-align: center;
					color: #aaa;
				}
			}

			.form-control {
				border: none;
				outline: 0;
				box-shadow: none;
				width: auto;
			}
		}

	}
}

.theme-dark {
	.dropzone {
		.file-drop-target.file-drop-dragging-over-target {
			background: rgba(255,255,255,0.1);
		}
	}

	.excel-table {
		tr {
			th {
				background: #888;
				color: white;
			}
		}
	}
}

