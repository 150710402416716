.float-right, .float-left {
	@include mobile {
		float: none !important;
	}
}

.pagination {
	user-select: none;

	li {
		cursor: pointer;

		&.disabled {
			cursor: not-allowed;
		}
	}
}

.row {
	margin: 0 !important;
}

#__react-alert__ > * {
	z-index: 2000;
}

.react-datepicker-wrapper {
	flex: 1 1 auto;
}

.input-group {
	flex-wrap: nowrap;
}

.react-tags__search-input {
	border: none !important;
}

.modal-content {
	.col-md-3, .col-md-1 {
		align-self: center !important;
	}
}

.badge {
	&.bg-secondary, &.bg-primary {
		color: white;
	}
}