table {
    &.sortable {
        table-layout: fixed;
        th {
            vertical-align: middle;
            svg {
                opacity: 0;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                &.active {
                    opacity: 1 !important;
                }
            }
            span.controls {
                margin-left: 0.8rem;
            }
            &:hover {
                svg {
                    opacity: 0.6;
                }
            }
        }
        td {
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            &.block {
                overflow: initial;
                text-overflow: initial;
                @include smallScreen {
                    white-space: initial;
                }
            }
        }
        tr {
            td {
                max-width: calc(33%);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: none;
                padding: 0.8rem;
                &.functional {
                    padding: 0;
                    vertical-align: middle;
                    svg {
                        opacity: 0;
                        transition: all 0.2s ease-in-out;
                        @include mobile {
                            opacity: 1;
                        }
                        &.svg-show {
                            opacity: 1;
                            margin-left: 25px;
                        }
                    }
                    button {
                        vertical-align: middle;
                        transition: all 0.1s ease-in-out;
                        outline: 0 !important;
                        box-shadow: none !important;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
        td,
        th {
            @include mobile {
                width: auto !important;
                overflow: visible !important;
                text-overflow: initial !important;
                white-space: normal !important;
            }
        }
        tbody {
            tr:hover {
                background: lighten($primary, 45%);
                td.functional {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }
        @include mobile {
            table-layout: initial;
        }
    }
}

.tableWrap {
    max-width: 100%;
    overflow-x: auto;
}