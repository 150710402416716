@mixin mobile {
	@media (max-width: $mobile-width) { @content; }
}

@mixin tablet {
	@media (min-width: $mobile-width) and (max-width: $tablet-width) { @content; }
}

@mixin smallScreen {
	@media (min-width: $tablet-width) and (max-width: $small-screen-width) { @content; }
}
