aside {
	.navbar-brand {
		padding: 1rem;
		max-height: 100px;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
	}

	&.main {
		min-width: 250px;
		max-width: 250px;
		min-height: 100vh;
		transition: all 0.3s ease-in-out;
		white-space: nowrap;
		overflow-x: hidden;
		position: relative;

		span {
			transition: all 0.2s ease-in-out;
		}

		.controls {
			position: absolute;
			bottom: 2rem;
			width: 100%;

			button {
				text-align: left;
				display: block;
				width: 100%;
				padding: 0.5rem 1.5rem;

				svg {
					padding: 0;
				}
			}
		}

		.version {
			position: fixed;
			bottom: 0;
			margin: 0;
			padding: 0.5rem 1rem;
			width: 100vw;
			max-width: inherit;
			text-align: right;
			font-style: italic;
			transition: all 0.2s ease-in-out;
			color: lighten($primary, 30%)
		}

		&.minimized {
			min-width: 60px;
			max-width: 60px;

			.version {
				max-width: 0;
				color: transparent;
				overflow: hidden;
			}

			.navbar-brand {
				max-height: 0;
				padding: 0;
				margin: 0;
			}
		}

		.navbar-brand {
			img {
				max-height: 20px;
				vertical-align: top;
				width: auto;
			}
		}

		@include mobile {
			min-width: 50px;
			max-width: 50px;

			.navbar-brand {
				max-height: 0;
				padding: 0;
				margin: 0;
			}
		}

		svg {
				width: 1.4rem;
				height: 1.4rem;
				margin-right: 1.2rem;
		}
	}
}
