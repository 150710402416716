.grid {
	display: grid;
	grid-gap: 2rem;
	padding: 2rem;

	@include smallScreen {
		grid-gap: 1rem;
	}

	&.half-half {
		grid-template-columns: 1fr 1fr;
	}

	&.three-thirds {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.seventy-thirty {
		grid-template-columns: 2fr 1fr;

		@include tablet {
			grid-template-columns: 1fr !important;
		}
	}

	&.ten-ninety {
		grid-template-columns: 1fr 9fr;
	}

	&.twenty-eighty {
		grid-template-columns: 2fr 8fr;

		&.right {
			& > *:nth-child(odd) {
				text-align: right;
				justify-self:  end;
			}
		}
	}

	&.form {
		grid-template-columns: 2fr 7fr;
		padding: 2rem 1rem;
		gap: 1rem;

		> * {
			align-self: center;
			box-sizing: border-box;
		}

		> label {
			margin: 0;
		}
	}

	&.thirty-seventy {
		grid-template-columns: 1fr 2fr;

		@include smallScreen {
			grid-template-columns: 1fr !important;
			grid-gap: 1rem;

			 & > * {
				 grid-column: auto !important;
			 }
		}
	}

	&.small-gap {
		grid-gap: 1rem;
	}

	@include smallScreen {
		padding: 2rem 0;
	}

	@include mobile {
		grid-template-columns: 1fr !important;
		padding: 2rem 0;
	}
}
