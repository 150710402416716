.wrapper {
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: stretch;
}

.spacer {
	flex-grow: 1;
}

svg.big {
	width: 1.4rem;
	height: 1.4rem;
	margin-right: 0.8rem;
}

.template-editor {
	background: white;
}

.description {
	font-style: italic;
	opacity: 0.7;
	font-size: 0.8rem;
}

.offline {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(8px);
	z-index: 5000;

	.content {
		background: white;
		padding: 2rem;
		width: 100%;
		max-width: 700px;
		text-align: center;
		border-radius: 3px;
		box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.8);
	}

	svg {
		font-size: 8rem;
	}
}

.loading-bar {
	box-sizing: border-box;
	width: 100%;
	margin: 3rem auto;
	border-radius: 10px;
	border: 4px solid transparent;
	position: relative;
	padding: 1px;

	
	&::before {
		content: "";
		border: 2px solid $primary;
		border-radius: 10px;
		position: absolute;
		top: -4px;
		right: -4px;
		bottom: -4px;
		left: -4px;
	}
	
	.loaderBar {
		position: absolute;
		border-radius: 10px;
		top: 0;
		right: 100%;
		bottom: 0;
		left: 0;
		background: $primary;
		width: 0;
		animation: borealisBar 2s linear infinite;
	}
}

@keyframes borealisBar {
	0% {
		left: 0%;
		right: 100%;
		width: 0%;
	}
	10% {
		left: 0%;
		right: 75%;
		width: 25%;
	}
	90% {
		right: 0%;
		left: 75%;
		width: 25%;
	}
	100% {
		left: 100%;
		right: 0%;
		width: 0%;
	}
}
