.toast {
	&.warning {
		background: $warning;
	}

	&.success {
		background: $success;
	}

	.toast-header {
		background: rgba(255,255,255,0.2);
	}
}
