.helpscreen {
	color: $primary;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 50%;
	width: 70%;
	margin: 0 auto;
	background: rgba(255,255,255,0.6);
	border: solid 1px $primary;
	padding: 2rem;
	border-bottom-left-radius: 8px;
	transform: translate(-50%, -100%);
	transition: all 0.2s ease-in-out;
	backdrop-filter: blur(6px);
	z-index: 10;

	&.active {
		transform: translate(-50%, 0);
	}

	h3 {
		font-size: 1.5rem;
	}

	.open {
		background: rgba(255,255,255,0.6);
		color: $primary;
		cursor: pointer;
		position: absolute;
		bottom: 1px;
		right: -1px;
		padding: 0.5rem 1rem;
		border: solid 1px $primary;
		transform: translateY(100%);
		border-top: solid 2px white;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
