@import "variables";
@import "mixins";
@import "utils";
@import "grids";

@import "theme-dark";

@import "~bootstrap/scss/bootstrap";
@import "~flag-icon-css/sass/flag-icon";
@import "~leaflet/dist/leaflet.css";
@import "overrides";

@import "headings";
@import "helpscreen";
@import "toasts";
@import "sidebars";
@import "forms";
@import "navigation";
@import "tables";
@import "sections";
@import "icons";
@import "buttons";


* {
	box-sizing: border-box;
}

html {
	font-size: 14px;
}

body {
	padding: 0;
	margin: 0;
	font-family: sans-serif;
}

main {
	background: #eee;
	flex-grow: 1;
	overflow-y: scroll;
}

.content > div {
	background : #eee;
	padding : 0 2rem 2rem 2rem;
}

.wrapper.login {
	background: url("../../../public/background_login.png") no-repeat center center;
	background-size: cover;
}

.copyright {
	position: absolute;
	bottom: 0;
	width: 100%;
	font-size: 0.8rem;
	color: #eee;
	text-align: center;
	padding: 1rem;
}
