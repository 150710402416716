nav {
	&.sidebar {
		li, a {
			display: block;
			list-style: none;
			padding: 1rem 1.5rem;
			border-bottom: solid 1px lighten($primary, 20%);
			cursor: pointer;
			color: white;
			text-decoration: none;

			&:hover {
				background: rgba(255,255,255,0.1);
			}

			@include mobile {
				padding: 0.8rem;
			}
		}

		a {
			&.active {
				background: rgba(0,0,0,0.1);
			}
		}
	}
}
